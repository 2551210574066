*, *::before, *::after {
  box-sizing: border-box;
}

body {
  background-color: #F3F3F3;
  margin: 0;
}

.container .ql-editor {
  width: 8.5in;
  min-height: 11in;
  padding: 1in;
  margin: 1rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
  background-color: white;
  border-radius: 16px;
}

.container .ql-container.ql-bubble {
  border: none;
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-family: 'Times New Roman', Times, serif;
}

@page {
  margin: 1in;
}

@media print {
  body {
    background: none;
  }

  .container .ql-editor {
    width: 6.5in;
    height: 9in;
    padding: 0;
    margin: 0;
    box-shadow: none;
    align-self: flex-start;
  }

  .container .ql-toolbar.ql-snow {
    display: none;
  }
}

.titlebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: clip;
  position: sticky;
  padding: 10px;
  top: 0;
  z-index: 4;
  background-color: #e1e1e1;
  border: none;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .5);
  border-radius: 0px 0px 16px 16px;
}

.title {
  font-size: 30px;
  justify-content: center;
}

.author {
  font-size: 15px;
  justify-content: center;
}


.spectral-extralight {
  font-family: "Spectral", serif;
  font-weight: 200;
  font-style: normal;
}

.spectral-light {
  font-family: "Spectral", serif;
  font-weight: 300;
  font-style: normal;
}

.spectral-regular {
  font-family: "Spectral", serif;
  font-weight: 400;
  font-style: normal;
}

.spectral-medium {
  font-family: "Spectral", serif;
  font-weight: 500;
  font-style: normal;
}

.spectral-semibold {
  font-family: "Spectral", serif;
  font-weight: 600;
  font-style: normal;
}

.spectral-bold {
  font-family: "Spectral", serif;
  font-weight: 700;
  font-style: normal;
}

.spectral-extrabold {
  font-family: "Spectral", serif;
  font-weight: 800;
  font-style: normal;
}

.spectral-extralight-italic {
  font-family: "Spectral", serif;
  font-weight: 200;
  font-style: italic;
}

.spectral-light-italic {
  font-family: "Spectral", serif;
  font-weight: 300;
  font-style: italic;
}

.spectral-regular-italic {
  font-family: "Spectral", serif;
  font-weight: 400;
  font-style: italic;
}

.spectral-medium-italic {
  font-family: "Spectral", serif;
  font-weight: 500;
  font-style: italic;
}

.spectral-semibold-italic {
  font-family: "Spectral", serif;
  font-weight: 600;
  font-style: italic;
}

.spectral-bold-italic {
  font-family: "Spectral", serif;
  font-weight: 700;
  font-style: italic;
}

.spectral-extrabold-italic {
  font-family: "Spectral", serif;
  font-weight: 800;
  font-style: italic;
}

.save {
  z-index: 6;
  right: 20px;
  top: 20px;
  position: absolute;
  color: #076eff;
}

.hoverable {transition: opacity 0.3s ease;}
.hoverable:hover {cursor: pointer; opacity: 70%;}
